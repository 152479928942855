import i18n from "@/i18n";

export const STATUS_NAMES = {
    "offline": "Không hoạt động",
    "online": "Đang hoạt động",
    "away": "Đi vắng",
    "busy": "Đang bận"
}
export const INTERNAL_REQUEST_DEFAULT = {
    type: null,
    basket_code: null,
    cell_id: null,
    money: null,
    id_employee_receipt: null,
    note: null,
    order_tracking_id: null,
    quantity: null,
    receipt_tracking_id: null,
    sku: null,
    working_shift: null,
    working_date: null,
}

export const INTERNAL_PICKUP_REDUCE = 3
export const INTERNAL_RETURN_HAS_MONEY = 4
export const INTERNAL_REQUEST_BONUS = 16
export const INTERNAL_REQUEST_PUNISHMENT = 17
export const INTERNAL_REQUEST_LOST_ITEM = 17
export const ALLOW_REQUEST_CREATE_TYPES = [
    10, 11, 12, 16, 17, 19, 21, 25,
    91, 99
]

export const RETURN_RECEIVE_REASONS = [
    {
        value: i18n.t('labels.missing_damaged'),
        text: i18n.t('labels.missing_damaged')
    },
    {
        value: i18n.t('labels.envelope_damaged'),
        text: i18n.t('labels.envelope_damaged')
    },
    {
        value: i18n.t('labels.water_damaged'),
        text: i18n.t('labels.water_damaged')
    },
    {
        value: i18n.t('labels.broken_damaged'),
        text: i18n.t('labels.broken_damaged')
    },
    {
        value: i18n.t('labels.exchanged_item'),
        text: i18n.t('labels.exchanged_item')

    },
    {
        value: i18n.t('labels.wrong_order'),
        text: i18n.t('labels.wrong_order')
    },
    {
        value: i18n.t('labels.excessive_return'),
        text: i18n.t('labels.excessive_return')
    },
    {
        value: i18n.t('labels.other'),
        text: i18n.t('labels.other')
    },
]

export const RETURN_IMPORT_REASONS = [
    {
        value: i18n.t('labels.destroy_return'),
        text: i18n.t('labels.destroy_return')
    },
    {
        value: i18n.t('labels.supplier_return'),
        text: i18n.t('labels.supplier_return')
    },
    {
        value: i18n.t('labels.appearance_not_qualified'),
        text: i18n.t('labels.appearance_not_qualified')
    },
    {
        value: i18n.t('labels.damaged_packaging'),
        text: i18n.t('labels.damaged_packaging')
    },
    {
        value: i18n.t('labels.damaged_goods'),
        text: i18n.t('labels.damaged_goods')
    },
    {
        value: i18n.t('labels.exchanged_order'),
        text: i18n.t('labels.exchanged_order')
    },
    {
        value: i18n.t('labels.no_good'),
        text: i18n.t('labels.no_good')
    },
    {
        value: i18n.t('labels.delivery_partial_1'),
        text: i18n.t('labels.delivery_partial_1')
    },
    {
        value: i18n.t('labels.out_of_date'),
        text: i18n.t('labels.out_of_date')
    },
    {
        value: i18n.t('labels.other_reason'),
        text: i18n.t('labels.other_reason')
    },
]

export const GOODS_ISSUE_STATE = [
    {
        text: i18n.t('labels.order_status_0_1'),
        value: 0
    },
    {
        text: i18n.t('labels.order_status_20'),
        value: 20
    },
    {
        text: i18n.t('labels.order_status_35'),
        value: 35
    },
    {
        text: i18n.t('labels.order_status_40'),
        value: 40
    },
    {
        text: i18n.t('labels.order_status_60_1'),
        value: 60
    },
    {
        text: i18n.t('labels.order_status_100'),
        value: 100
    },
    {
        text: i18n.t('labels.order_status_160'),
        value: 160
    },
    {
        text: i18n.t('labels.order_status_200'),
        value: 200
    },
    {
        text: i18n.t('labels.order_status_220'),
        value: 220
    },
    {
        text: i18n.t('labels.order_status_240'),
        value: 240
    },
]

export const GOODS_ISSUE_STATE_NAME = {
    0: i18n.t('labels.order_status_0_1'),
    20: i18n.t('labels.order_status_20'),
    35: i18n.t('labels.order_status_35'),
    40: i18n.t('labels.order_status_40'),
    60: i18n.t('labels.order_status_60_1'),
    100: i18n.t('labels.order_status_100'),
    160: i18n.t('labels.order_status_160'),
    200: i18n.t('labels.order_status_200'),
    220: i18n.t('labels.order_status_220'),
    240: i18n.t('labels.order_status_240'),
}

export const PICKUP_COMMAND_STATUS = [
    {
        text: i18n.t('labels.notify_type_15'),
        value: 0
    },
    {
        text: i18n.t('labels.picked'),
        value: 1
    },
]

export const PICKUP_COMMAND_PROCESS_TYPE = [
    {
        text: '1',
        value: 1
    },
    {
        text: '2',
        value: 2
    },
    {
        text: '3',
        value: 3
    },
]

export const PICKUP_COMMAND_TYPE = [
    {
        text: i18n.t('labels.pick_type_0'),
        value: 0
    },
    {
        text: i18n.t('labels.pick_type_1'),
        value: 1
    },
    {
        text: i18n.t('labels.pick_type_2'),
        value: 2
    },
    {
        text: i18n.t('labels.pick_type_3'),
        value: 3
    },
    {
        text: i18n.t('labels.pick_type_4'),
        value: 4
    },
    {
        text: i18n.t('labels.pick_type_5'),
        value: 5
    },
    {
        text: i18n.t('labels.pick_type_6'),
        value: 6
    },
    {
        text: i18n.t('labels.pick_type_100'),
        value: 100
    },
    {
        text: i18n.t('labels.pick_type_101'),
        value: 101
    },
]

export const PICKUP_COMMAND_TYPE_2 = [
    {
        text: i18n.t('labels.pick_type_0'),
        value: 0
    },
    {
        text: i18n.t('labels.pick_type_2_1'),
        value: 1
    },
    {
        text: i18n.t('labels.pick_type_2_2'),
        value: 2
    },
    {
        text: i18n.t('labels.pick_type_2_3'),
        value: 3
    },
    {
        text: i18n.t('labels.pick_type_2_4'),
        value: 4
    },
    {
        text: i18n.t('labels.pick_type_2_5'),
        value: 5
    },
    {
        text: i18n.t('labels.pick_type_2_6'),
        value: 6
    },
    {
        text: i18n.t('labels.pick_type_2_100'),
        value: 100
    },
    {
        text: i18n.t('labels.pick_type_101'),
        value: 101
    },
]

export const DELIVERY_STATE = [
    {
        text: i18n.t('labels.delivery_status_50'),
        value: 50
    },
    {
        text: i18n.t('labels.delivery_status_55'),
        value: 55
    },
    {
        text: i18n.t('labels.delivery_status_100'),
        value: 100
    },
    {
        text: i18n.t('labels.delivery_status_200'),
        value: 200
    },
    {
        text: i18n.t('labels.delivery_status_225'),
        value: 225
    },
    {
        text: i18n.t('labels.delivery_status_250'),
        value: 250
    },
    {
        text: i18n.t('labels.delivery_status_275'),
        value: 275
    },
    {
        text: i18n.t('labels.delivery_status_400'),
        value: 400
    },
    {
        text: i18n.t('labels.delivery_status_401'),
        value: 401
    },
    {
        text: i18n.t('labels.delivery_status_402'),
        value: 402
    },
    {
        text: i18n.t('labels.delivery_status_700'),
        value: 700
    },
    {
        text: i18n.t('labels.delivery_status_800'),
        value: 800
    },
    {
        text: i18n.t('labels.cancel_process'),
        value: 999
    },
]

export const IMAGE_OPTIONS = [
    {
        text: i18n.t('labels.yes'),
        value: 'y'
    },
    {
        text: i18n.t('labels.no'),
        value: 'n'
    },
]

export const RECEIPT_DETAIL_STATUS_OPTIONS = [
    {
        text: i18n.t('labels.not_process'),
        value: 'ny'
    },
    {
        text: i18n.t('labels.processing_1'),
        value: 'd'
    },
    {
        text: i18n.t('labels.processed_1'),
        value: 'y'
    },
]

export const TRANSPORT_OPTIONS = [
    {
        text: 'Road',
        value: 'road'
    },
    {
        text: 'Fly',
        value: 'fly'
    },
]

export const YES_NO_OPTIONS = [
    {
        text: 'Yes',
        value: 1
    },
    {
        text: 'No',
        value: 0
    },
]

export const NUMBER_OPTIONS = {
    length: 11,
    precision: 0,
}

export const MONEY_OPTIONS = {
    length: 11,
    precision: 2,
    suffix: "",
}

export const GRAM_OPTIONS = {
    length: 11,
    precision: 0,
    suffix: "g",
}

export const GOODS_RETURN_SEAL_STATUS = [
    {
        text: i18n.t('labels.seal_intact'),
        value: 1
    },
    {
        text: i18n.t('labels.lost_seal'),
        value: 0
    },
]

export const WORKING_TIMES = [
    {
        text: i18n.t('labels.morning'),
        value: 1
    },
    {
        text: i18n.t('labels.afternoon'),
        value: 2
    },
    {
        text: i18n.t('labels.evening'),
        value: 3
    },
    {
        text: i18n.t('labels.over_time'),
        value: 0
    },
]

export const DAY_OFF_TYPES = [
    {
        text: i18n.t('labels.day_off_type_0'),
        value: 0
    },
    {
        text: i18n.t('labels.day_off_type_1'),
        value: 1
    },
    {
        text: i18n.t('labels.day_off_type_2'),
        value: 2
    },
]

export const SOURCES = [
    {
        text: 'SSC',
        value: -2
    },
    {
        text: i18n.t('labels.tvc'),
        value: -1
    },
    {
        text: 'Shopee',
        value: 1
    },
    {
        text: 'Lazada',
        value: 2
    },
    {
        text: 'Tiktok',
        value: 5
    },
]

export const BARCODE_MAT_NIEM_PHONG = 'SSC-MNP-SSC'
export const BARCODE_REMOVE = 'SSC-RMRT-SSC'
export const BARCODE_HU_HAI = 'SSC-HUHAI-SSC'
export const BARCODE_REMOVE_HANDOVER = 'SSC-RMHO-SSC'
export const BARCODE_REMOVE_RECEIPT = 'SSC-RMRC-SSC'
export const BARCODE_FINISH_PACKING_1SKU = 'SSC-F1SKU-SSC'
export const BARCODE_MULTIPACK = 'SSC-MULTIPACK-SSC'
export const BARCODE_LOST_LABEL = 'SSC-RETURNITEMLOSTLABEL-SSC'
export const BARCODE_PICKUP_LATER = '88SSC98'

export const WAREHOUSE_TABLE_PACKING = 2
export const WAREHOUSE_TABLE_RECEIPT = 1

export const ACTIVITIES_LOGS = {
    ACTION: {
        RECEIPT: 1, // nhap kho
        RECEIPT_STOWING: 2, // len ke nhap kho
        PICKUP: 3, // pickup
        PACKING: 4, // dong goi
        HANDOVER: 5, // ban giao van chuyen
        RETURN_RECEIVE: 6, // nhan hoan
        RETURN_IMPORT: 7, // nhap hoan
        RETURN_STOWING: 8, // len ke hang hoan
        RECOUP: 9, // boi thuong,
    },
    JOB: {
        ITEM: 1, // item
        ORDER: 2, // order
        FAIL_EMPLOYEE: 3, // fail by employee
        FAIL_SSC: 4, // fail by ssc,
        LOG: 99,
    },
    WORKING_SHIFT: {
        SA: 1, // ca sang
        CH: 2 // ca chieu
    },
}

// export const NOTIFY_TYPE_OPTIONS = [1, 2, 3, 11, 12, 13, 14, 15, 16, 98, 99]
export const NOTIFY_TYPE_OPTIONS = [12, 13, 15, 16, 17, 97, 98]
export const NOTIFY_STATUS_OPTIONS = [0, 1]

export const SSC_DELIVERY_CONTRACT = -1
export const TVC_DELIVERY_CONTRACT = -2

export const NOTIFY_TYPE_COLOR = {
    1: 'purple lighten-3',
    2: 'error',
    3: 'warning',
    11: 'yellow lighten-2',
    12: 'amber lighten-2',
    13: 'orange lighten-2',
    14: 'deep-orange lighten-2',
    15: 'brown lighten-2',
    16: 'blue-grey lighten-2',
    17: 'warning',
    98: 'info',
    99: 'teal lighten-3',
}

export const ID_DELIVERY_GHN = 3
export const ID_DELIVERY_GHTK = 4
export const ID_DELIVERY_VNP = 33
export const ID_DELIVERY_JNT = 36
export const ID_DELIVERY_SPX = 74
export const ALLOW_FIELD_DELIVERY_ACCOUNT_GHTK = ['token']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_GHN = ['token', 'shop_id']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_SPX = ['app_id', 'app_secret', 'user_id', 'user_secret']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_JNT = ['company_id', 'customer_id', 'token']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_VNP = ['username', 'password']

export const STAMP_SIZE_OPTIONS = [
    {
        value: '200x100',
        text: '200x100'
    },
    {
        value: '100x25',
        text: '100x25'
    },
    {
        value: '100x50',
        text: '50x50x2'
    },
    {
        value: '100x20',
        text: '50x20x2'
    },
    {
        value: '90x15',
        text: '30x15x3'
    },
    {
        value: '90x15_horizontal',
        text: i18n.t('labels.30_15_3_horizontal')
    },
]

export const zoneToIndexing = (zone) => {
    const zones = {
        '': 0,
        null: 0,
        'A': 1000000000,
        'B': 2000000000,
        'C': 3000000000,
        'D': 4000000000,
        'E': 5000000000,
        'F': 6000000000,
        'G': 7000000000,
        'H': 8000000000,
        'I': 9000000000,
        'J': 10000000000,
        'K': 11000000000,
        'L': 12000000000,
        'M': 13000000000,
        'N': 14000000000,
        'O': 15000000000,
        'P': 16000000000,
        'Q': 17000000000,
        'R': 18000000000,
        'S': 19000000000,
        'T': 20000000000,
        'U': 21000000000,
        'V': 22000000000,
        'W': 23000000000,
        'X': 24000000000,
        'Y': 25000000000,
        'Z': 26000000000,
    }
    return zones[zone]
}

export const positionToIndexing = (position, type) => {
    const positionIndexing = 1000000
    if (position === 1) {
        return positionIndexing
    } else {
        const isEven = position % 2 === 0
        if (type === 1) {
            return positionIndexing * (isEven ? position : (position - 1))
        } else {
            return positionIndexing * (isEven ? (position - 1) : position)
        }

    }
}

export const levelToIndexing = (level) => {
    const levels = {
        'A': 1,
        'B': 2,
        'C': 3,
        'D': 4,
        'E': 5,
        'F': 6,
        'G': 7,
        'H': 8,
        'I': 9,
        'J': 10,
        'K': 11,
        'L': 12,
        'M': 13,
        'N': 14,
        'O': 15,
        'P': 16,
        'Q': 17,
        'R': 18,
        'S': 19,
        'T': 20,
        'U': 21,
        'V': 22,
        'W': 23,
        'X': 24,
        'Y': 25,
        'Z': 26,
    }
    return levels[level]
}

export const cellToIndexing = (cell) => {
    return cell * 1000
}

export const cellPositionToIndexing = (position, type) => {
    const isEven = position % 2 === 0
    if (type === 1) {
        return isEven ? 0 : 100
    } else {
        return isEven ? 100 : 0
    }
}

export const calIndexing = (zone, position, level, cell, type) => {
    console.log(zone, position, level, cell, type)
    return zoneToIndexing(zone) + positionToIndexing(position, type) + levelToIndexing(level) + cellToIndexing(cell) + cellPositionToIndexing(position, type)
}

export const REQUEST_TYPE_OPTIONS = [
    {
        text: i18n.t('labels.update_order_stt'),
        value: 101,
        department: 1,
    },
    {
        text: i18n.t('labels.check_packing'),
        value: 201,
        department: 2,
    },
    {
        text: i18n.t('labels.check_restock'),
        value: 202,
        department: 3,
    },
    {
        text: i18n.t('labels.urge_delivery'),
        value: 301,
        department: 3,
    },
    {
        text: i18n.t('labels.change_cod'),
        value: 302,
        department: 3,
    },
    {
        text: i18n.t('labels.cancel_delivery'),
        value: 303,
        department: 3,
    },
    {
        text: i18n.t('labels.schedule_delivery'),
        value: 304,
        department: 3,
    },
    {
        text: i18n.t('labels.change_recipient'),
        value: 305,
        department: 3,
    },
    {
        text: i18n.t('labels.collate_delivery_report'),
        value: 401,
        department: 4,
    },
    {
        text: i18n.t('labels.other'),
        value: 0,
        department: null,
    },
]

export const REQUEST_DEPARTMENT_OTPIONS = [
    {
        text: i18n.t('labels.system'),
        value: 1,
    },
    {
        text: i18n.t('labels.warehouse'),
        value: 2,
    },
    {
        text: i18n.t('labels.delivery'),
        value: 3,
    },
    {
        text: i18n.t('labels.accounting'),
        value: 4,
    },
]

export const ORDER_REQUEST_DEFAULT = {
    tracking_id: null,
    type: null,
    incharge_department: null,
    content: null,
}

export const PACKAGING_TYPES = [
    'carton', 'pe', 'kraft_bubble', 'no_pack', 'bubble', 'non_woven_fabric', 'labels', 'ink', 'tape', 'paper', 'ribbon'
]

export const PACKAGING_UNITS = [
    {
        value: 'cuộn',
        text: i18n.t('labels.roll_1'),
    },
    {
        value: 'bó',
        text: i18n.t('labels.bundle'),
    },
    {
        value: 'hộp',
        text: i18n.t('labels.box'),
    },
    {
        value: 'tập',
        text: i18n.t('labels.pile'),
    },
    {
        value: 'cây',
        text: i18n.t('labels.shrink'),
    },
]

export const FIND_GOODS_CUSTOMER_STATUS = [0, 1, 2, 3]

export const ORDER_RETURN_SOURCES = [
    {
        text: i18n.t('labels.canceled_order'),
        value: 1,
    },
    {
        text: i18n.t('labels.handed_over_order'),
        value: 0,
    },
]
export const EXPORT_DEFECTIVE_HISTORY = [
    {
        text: i18n.t('labels.export_return_supplier'),
        value: 1,
    },
    {
        text: i18n.t('labels.destroy_defective'),
        value: 2,
    },
    {
        text: i18n.t('labels.fix_defective'),
        value: 3,
    },
    {
        text: i18n.t('labels.clearance'),
        value: 4,
    },
]
export const DEPARTMENT_LIST = [
    {
        text: i18n.t('labels.security'),
        value: 1,
    },
    {
        text: i18n.t('labels.bod'),
        value: 2,
    },
    {
        text: i18n.t('labels.cutting'),
        value: 3,
    },
    {
        text: i18n.t('labels.cskh'),
        value: 4,
    },
    {
        text: i18n.t('labels.store'),
        value: 5,
    },
    {
        text: i18n.t('labels.admin_dept'),
        value: 6,
    },
    {
        text: i18n.t('labels.printing_dept'),
        value: 7,
    },
    {
        text: i18n.t('labels.it_dept'),
        value: 8,
    },
    {
        text: i18n.t('labels.accounting_dept'),
        value: 9,
    },
    {
        text: i18n.t('labels.warehouse'),
        value: 10,
    },
    {
        text: i18n.t('labels.mkt_dept'),
        value: 11,
    },
    {
        text: i18n.t('labels.sewing_dept'),
        value: 12,
    },
    {
        text: i18n.t('labels.hr'),
        value: 13,
    },
    {
        text: i18n.t('labels.seasonal_dept'),
        value: 14,
    },
    {
        text: i18n.t('labels.sales'),
        value: 15,
    },
    {
        text: i18n.t('labels.ecommerce'),
        value: 16,
    },
    {
        text: i18n.t('labels.cleanning_dept'),
        value: 17,
    },
]
export const POSITION_LIST = [
    {
        text: i18n.t('labels.timekeep_only'),
        value: 1,
    },
    {
        text: i18n.t('labels.store_manager'),
        value: 2,
    },
    {
        text: i18n.t('labels.dept_director'),
        value: 3,
    },
    {
        text: i18n.t('labels.full_time'),
        value: 4,
    },
    {
        text: i18n.t('labels.part_time'),
        value: 5,
    },
    {
        text: i18n.t('labels.seasonal_staff'),
        value: 6,
    },
    {
        text: i18n.t('labels.probation_staff'),
        value: 7,
    },
    {
        text: i18n.t('labels.deputy_wh_manager'),
        value: 8,
    },
    {
        text: i18n.t('labels.vice_director'),
        value: 9,
    },
    {
        text: i18n.t('labels.leader'),
        value: 10,
    },
    {
        text: i18n.t('labels.director'),
        value: 11,
    },
    {
        text: i18n.t('labels.wh_manager'),
        value: 12,
    },
]
export const DEPT_TASK_REQUEST_LIST = [
    {
        text: i18n.t('labels.warranty'),
        value: 1,
    },
    {
        text: i18n.t('labels.system'),
        value: 2,
    },
    {
        text: i18n.t('labels.accounting_dept'),
        value: 3,
    },
    {
        text: i18n.t('labels.hr'),
        value: 4,
    },
    {
        text: i18n.t('labels.ecommerce_manager'),
        value: 5,
    },
    {
        text: i18n.t('labels.administration'),
        value: 6,
    },
    {
        text: i18n.t('labels.dispatcher'),
        value: 7,
    },
]
export const CLASSIFICATION_TASK_REQUEST_LIST = [
    {
        text: i18n.t('labels.auto_unauthorized_leave'),
        value: 1,
    },
    {
        text: i18n.t('labels.auto_wrong_stock'),
        value: 2,
    },
    {
        text: i18n.t('labels.auto_return_not_received'),
        value: 3,
    },
    {
        text: i18n.t('labels.auto_final_status_changed'),
        value: 4,
    },
    {
        text: i18n.t('labels.redeem_timekeeping'),
        value: 5,
    },
    {
        text: i18n.t('labels.return_not_received'),
        value: 6,
    },
    {
        text: i18n.t('labels.money_in_package'),
        value: 7,
    },
    {
        text: i18n.t('labels.possible_miss_handover'),
        value: 8,
    },
    {
        text: i18n.t('labels.update_order_stt'),
        value: 9,
    },
    {
        text: i18n.t('labels.add_allowance'),
        value: 10,
    },
    {
        text: i18n.t('labels.deliver_again'),
        value: 11,
    },
    {
        text: i18n.t('labels.subtract_received'),
        value: 12,
    },
    {
        text: i18n.t('labels.subtract_allowance'),
        value: 13,
    },
    {
        text: i18n.t('labels.urge_delivery'),
        value: 14,
    },
    {
        text: i18n.t('labels.schedule_delivery'),
        value: 15,
    },
]
export const FINDING_STATUS = [
    {
        text: i18n.t('labels.find_goods_status_1'),
        status: 1,
        img: require("@/assets/internal_requests/1_Cho_xu_Ly.png"),
    },
    {
        text: i18n.t('labels.find_goods_status_2'),
        status: 2,
        img: require("@/assets/internal_requests/2_Dang_xu_ly.png"),
    },
    {
        text: i18n.t('labels.find_goods_status_3')
        ,
        status: 3,
        img: require("@/assets/internal_requests/3_Cho_xac_nhan.png"),
    },
    {
        text: i18n.t('labels.find_goods_status_4'),
        status: 4,
        img: require("@/assets/internal_requests/4_Da_hoan_thanh.png"),
    },
]
export const ORDER_STATUS = [
    {
        key: "thieu_hang",
        title: i18n.t('labels.order_status_20'),
        color: "#ff5252",
    },
    {
        key: "cho_ma_van_chuyen",
        title: i18n.t('labels.order_status_35_1'),
        color: "#ff5252",
    },
    {
        key: "thieu_tem",
        title: i18n.t('labels.missing_stamp'),
        color: "#ff5252",
    },
    {
        key: "tiep_nhan",
        title: i18n.t('labels.order_status_40'),
        color: "#fb8c00",
    },
    {
        key: "dang_xu_ly",
        title: i18n.t('labels.order_status_60_1'),
        color: "#1976d2",
    },
    {
        key: "pickup",
        title: i18n.t('labels.order_status_100'),
        color: "#1976d2",
    },
    {
        key: "dong_goi",
        title: i18n.t('labels.order_status_160'),
        color: "#1976d2",
    },
    {
        key: "ban_giao",
        title: i18n.t('labels.order_status_200'),
        color: "#4caf50",
    },
    {
        key: "cho_hoan",
        title: i18n.t('labels.wait_return'),
        color: "#fb8c00",
    },
    {
        key: "nhan_hoan",
        title: i18n.t('labels.order_status_220'),
        color: "#fb8c00",
    },
    {
        key: "nhap_hoan",
        title: i18n.t('labels.order_status_240'),
        color: "#4caf50",
    }
]
export const EMPLOYEE_TYPE = [
    {
        text: i18n.t('labels.summary'),
        value: "summary",
    },
    {
        text: i18n.t('labels.by_employee'),
        value: "employee",
    }
]
export const DATA_TYPE = [
    {
        text: i18n.t('labels.summary'),
        value: "summary",
    },
    {
        text: i18n.t('labels.by_date'),
        value: "date",
    },]
export const SHIFT_TYPE = [
    {
        text: i18n.t('labels.summary'),
        value: "summary",
    },
    {
        text: i18n.t('labels.by_shift'),
        value: "shift",
    }
]
export const UNIT_TYPE = [
    {
        text: i18n.t('labels.item_1'),
        value: "item",
    },
    {
        text: i18n.t('labels.order_1'),
        value: "order",
    }
]
export const VIEW_OPTION = [
    {
        text: i18n.t('labels.detail'),
        value: "view0",
    },
    {
        text: i18n.t('labels.summary'),
        value: "view1",
    }
]